<template>
  <div>
    <v-tabs 
      background-color="appBackground darken-1"
      align-with-title
    >
      <v-tab
        v-for="(item, index) in items"
        :key="index"
        :to="item.to"
        active-class="selected-color"
        exact
      >
        {{ $t(item.name) }}
      </v-tab>
    </v-tabs>

    <v-container class="pt-0">
      <router-view />
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'SecurityTabs',

  data() {
    return {
      items: [
        {
          name: "security_groups",
          to: {
            name: "SecurityGroup",
          }
        },
        {
          name: "mark_categories",
          to: {
            name: "SecurityCategory",
          }
        }
      ]
    }
  },
}
</script>
